import store from "./../store"
const axios = require("axios")
const randomString = require("randomstring")

export function triggerAction(action, payload, loading=true) {
    return new Promise((resolve, reject) => {
        if(loading) { store.commit("SET_LOADING_STATE", true) }
        store
        .dispatch(action, payload)
        .then(data => {
            store.commit("SET_LOADING_STATE", false)
            resolve(data)
        })
        .catch(err => {
            store.commit("SET_SNACKBAR", { message: "There seems to be an error" })          
            reject(err)
        })
    })
}

export function triggerActionExt(action, payload) {
    return new Promise((resolve, reject) => {
        if(!payload.hideLoader)
            store.commit("SET_LOADING_STATE", true)        
        store.dispatch(action, payload.data)
        .then(() => {
            if (payload.resolveMsg) { 
                store.commit("SET_SNACKBAR", { message: payload.resolveMsg } )
            }
            resolve()
        })
        .catch(err => {
            store.commit("SET_SNACKBAR", { message: "There seems to be an error" })
            reject(err)
        })
        .finally(() => {
            if(!payload.hideLoader)
                store.commit("SET_LOADING_STATE", false)            
        })
    })
}


export const triggerBatchActions = (batch) => {
    return new Promise((resolve,reject) => {
        store.commit("SET_LOADING_STATE", true)
        batch.forEach(async b => {
            await triggerAction(b.action, b.payload, false)
        })
        store.commit("SET_LOADING_STATE", false)
        resolve()
    })

}

export function ProgressLoader(handler) {
    return new Promise((resolve) => {
        return handler()
    })
}

export function generateRegistrationId() {
    return 'TTM'+randomString.generate({
        length: 7,
        charset: 'numeric'
      });      
}


export const sendEmail = (data) => {
    return new Promise((resolve, reject) => {
        axios.post('https://us-central1-vegastep-devbox.cloudfunctions.net/sendTicket2MbaEmail', data)
        .then(res => !!res.success? resolve(res) : reject(res))            
        .catch(e => reject(e));                                
    })    
}