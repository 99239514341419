import { personsRef } from "../services/Database";
import { sendEmail } from "./../services/common"

export function addPerson(person) {
  return personsRef
    .add(person)
    .then(async doc => {      
      try {
        await sendEmail(person)        
      } catch (err) {        
        return err
      }
      return doc.data()
    })
    .catch(err => err)
}

export function updatePerson(id, val) {
  return personsRef 
  .where('registrationId', '==', id)  
  .get()
  .then(snaps => {
    snaps.forEach(async snap => {      
      await personsRef.doc(snap.id)
        .update({
          present: val
        })
        .then(doc => Promise.resolve())
        .catch(err => err)
    })
  })
}

export function getPersonByField(field, val) {
  return personsRef
    .where("phone", "==", val)
    .where("year", "==", 2022)
    .get()
    .then(snap => {
      return snap.docs[0]? Promise.resolve(snap.docs[0].data()) : Promise.resolve(false)
    })
}

export function getPersonsCollection() {
  let result = []  
  return personsRef
    .where("year", "==", 2022)
    .orderBy('timestamp', 'desc')    
    .get()
    .then(snaps => {
      snaps.forEach(snap => result.push(snap.data()))      
      return Promise.resolve(result)
    })
    .catch(err => Promise.reject(err))  
}