<template>
  <div>
    <app-parallax :height="440" :disableScroll="$vuetify.breakpoint.smAndDown" src="exhibitor1.jpg" >  
    <v-container class="mt-5" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex class="text-center pb-4" xs12 data-aos="fade-up" data-aos-duration="1000">
          <h2 class="app-display-1 white--text font-weight-light" style="letter-spacing:3px !important;">
            EXHIBITORS
          </h2>
          <div class="grey--text text--lighten-3  mt-2" style="letter-spacing:1px; font-family:Noto Serif">
            Past Event Collaborators
          </div>            
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex 
          md10
          offset-md1
          class="py-5"  data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300">
          <Slider :options="flickityOptions">
            <div class="carousel-cell" v-for="exhibitor in exhibitors" :key="exhibitor.name">              
              <v-layout align-center justify-center row wrap>
                <div style="width:150px">
                  <v-img contain :src="exhibitor.logo" width="150" height="150" style="border-radius:50%;background:white;padding:20px;" />                          
                  <h3 class="white--text text-center py-4 font-weight-light">{{exhibitor.name}}</h3> 
                </div>                             
              </v-layout>
            </div>
          </Slider>                                
        </v-flex>
      </v-layout>
    </v-container>    
    <v-overlay 
      absolute
      color="primary"
      opacity="0.3"
      z-index="-1"
    />       
    </app-parallax>
  </div>
</template>

<script>
export default {
  data: () => ({
    exhibitors:[
       {logo: require('@/assets/institutes/AsiaPacific.jpg'), name: 'Asia Pacific Institute of Management'},
        {logo: require('@/assets/institutes/FIIB.png'), name: 'FIIB'},
        {logo: require('@/assets/institutes/gbs-logo.png'), name: 'Globsyn Business School'},
        {logo: require('@/assets/institutes/lexicon.jpg'), name: 'Lexicon Management Institute'},
        {logo: require('@/assets/institutes/ISBR.png'), name: 'ISBR Business School'},
        {logo: require('@/assets/institutes/BIBS.jpg'), name: 'Bengal Institute of Business Studies'},
        {logo: require('@/assets/institutes/DMTIMS LOGO.jpg'), name: 'DMTIMS'},
        {logo: require('@/assets/institutes/EIILM.jpg'), name: 'EIILM'},
        {logo: require('@/assets/institutes/fostiima.jpg'), name: 'FOSTIIMA Business School'},
        {logo: require('@/assets/institutes/GNIOT LOGO.jpg'), name: 'Greater Noida Institute of Technology'},
        {logo: require('@/assets/institutes/IMM.jpeg'), name: 'IMM'},
        {logo: require('@/assets/institutes/IILM.jpg'), name: 'IILM'},
        {logo: require('@/assets/institutes/iibs_logo.jpg'), name: 'International Institute of Business Studies'},
        {logo: require('@/assets/institutes/ismr.jpg'), name: 'International School of Management & Research'},
        {logo: require('@/assets/institutes/izee.jpg'), name: 'IZEE'},
        {logo: require('@/assets/institutes/kcm.jpg'), name: 'Karnataka College of Management'},
        {logo: require('@/assets/institutes/piet.png'), name: 'P.I.E.T'},
        {logo: require('@/assets/institutes/parul.jpeg'), name: 'Parul University'},
        {logo: require('@/assets/institutes/praxis.png'), name: 'Praxis Business School'},
        {logo: require('@/assets/institutes/presidency.jpg'), name: 'Presidency University'},
        {logo: require('@/assets/institutes/riim.jpg'), name: 'RIIM'},
        {logo: require('@/assets/institutes/sbs.jpg'), name: 'Shanti Business School'},
        {logo: require('@/assets/institutes/sunstone.jpg'), name: 'Sunstone Eduversity'},
        {logo: require('@/assets/institutes/UNITEDWORLDSCHOOLOFBUSINESS.jpg'), name: 'Unitedworld School of Business'}
        //2021
        // {logo: require('@/assets/institutes/FIIB.png'), name: 'FIIB'},
        // {logo: require('@/assets/institutes/gbs-logo.png'), name: 'Globsyn Business School'},
        // {logo: require('@/assets/institutes/BIBS.jpg'), name: 'BIBS'},
        // {logo: require('@/assets/institutes/DOONBUSINESSSCHOOL.png'), name: 'Doon Business School'},
        // {logo: require('@/assets/institutes/piet.png'), name: 'P.I.E.T'},
        // {logo: require('@/assets/institutes/rrinstitution.jpg'), name: 'RR Institution'},
        // {logo: require('@/assets/institutes/UNITEDWORLDSCHOOLOFBUSINESS.jpg'), name: 'Unitedworld School of Business'}
        //2019
        // {logo: require('@/assets/institutes/GLBAJAJ.jpg'), name: 'Gl Bajaj'},
        // {logo: require('@/assets/institutes/IEM.png'), name: 'IEM'},
        // {logo: require('@/assets/institutes/KL.png'), name: 'KL University'},
        // {logo: require('@/assets/institutes/AIMS.png'), name: 'AIMS'},
        // {logo: require('@/assets/institutes/AJEENKYA.png'), name: 'Ajeenkya University'},
        // {logo: require('@/assets/institutes/iibs_logo.jpg'), name: 'IIBS'},
        // {logo: require('@/assets/institutes/ITEMUNIVERSITY.png'), name: 'Item University'},
        // {logo: require('@/assets/institutes/ITMGROUPOFINSTITUTIONS.png'), name: 'ITM Group of Institutions'},
        // {logo: require('@/assets/institutes/jisulogo.png'), name: 'Jis University'},
        // {logo: require('@/assets/institutes/MODYUNIVERSITY.png'), name: 'Mody University'},
        // {logo: require('@/assets/institutes/NSHM.jpg'), name: 'NSHM'},
        // {logo: require('@/assets/institutes/sanjivani_group_logo_.png'), name: 'Sanjivani Group of Institution'},
      ],
    flickityOptions: {
      contain: true,  
      freeScroll : true,
      prevNextButtons: true,
      adaptiveHeigth: true,
      autoPlay: true,
      pageDots: false,
      groupCells: '80%',
      wrapAround: true    
    }
  }),
  methods: {
    next() {
      this.$refs.flickity.next();
    },    
    previous() {
      this.$refs.flickity.previous();
    }
  }
}
</script>

<style scoped>
.carousel-cell {
  width: 180px;
  height: 280px;
  padding-top:30px;
  margin-right: 10px;
  background: transparent;
  overflow: hidden;
  /* border-radius: 50%; */
}

@media(max-width:400px) {
  .carousel-cell {
    width: 85%;
  }
}
  
</style>