<template>
  <v-app>
    <v-content>
      <!-- <v-btn @click="backup">BACKUP</v-btn> -->
      <Navbar />
      <!-- <Carousel /> -->
      <router-view></router-view>
      <!-- <Footer /> -->
      <app-actions />
    </v-content>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import AppActions from "@/components/AppActions"
import { backup } from "./models/Test.js"

export default {
  name: 'App',
  components: {
   Navbar,
   Footer,
  AppActions
  },
  data: () => ({
    //
  }),
  methods: {
    async backup() {
      console.log("Backing up...")
      await backup()
    }
  }
};
</script>

<style>
@font-face {
  font-family: Evogria;
    src: url('./assets/Evogria.woff') format('woff'),
          url('./assets/Evogria.ttf')  format('truetype');
}   
body{
  background: #FBF2E5 !important;
}

.v-application {
  font-family: PT Serif !important;
}

.header-title{  
  color:#0392cf; 
  font-family: 'Evogria' !important;
}

.my-50{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.app-display-1 {
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-family: Evogria !important;
  
}

.app-headline {
  font-size: 1.5rem !important;
  letter-spacing: normal;
  line-height: 2rem;
}

.app-display-1-large {
  font-size: 2.8rem;
  line-height: 2.6rem;
  font-family: "PT Serif", sans-serif !important;
}
.app-body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing:0.0178571429em !important;
  line-height: 1.25rem;
}

.heading-font-family {
  font-family:Julius Sans One !important; 
}

.text-font-family {
  font-family: PT Serif !important;
}
</style>