<template>
  <div class="my-5 py-5">
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex class="text-center pb-4" xs12 data-aos="fade-up" data-aos-duration="1200">
          <h2 class="app-display-1 secondary--text font-weight-light" style="letter-spacing:3px !important;">
            FAQs
          </h2>
          <div class="grey--text text--darken-1  mt-2" style="letter-spacing:1px; font-family:Noto Serif">
            Frequently asked questions
          </div>  
        </v-flex>
      </v-layout>
      <v-layout class="mt-5" row wrap>
        <v-flex xs12 md10 offset-md1  data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300">
          <v-expansion-panels focusable v-model="activePanel">
            <v-expansion-panel
              v-for="faq in faqs"
              :key="faq.question"
            >
              <v-expansion-panel-header>   
                {{ faq.question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider />
                <div class="mt-4">
                  <span v-html="faq.answer">

                  </span>
                  
                  <!-- {{ faq.answer }} -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>          
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>    

<script>
export default {
  data(){
    return{

      faqs:[{question:'1. Why should i attend the TICKET TO MBA event?', answer:`
      Ticket to MBA is an incredible and unique concept created by our experts in Premier Edu; which is an effective way to bring MBA aspiring students and all educational institutes under one roof. The event is organized mainly 
      <ul>
        <li>To create awareness about Management Courses among the students of North East India.</li>
        <li>To enhance the knowledge regarding MBA courses offered by various institutes across india.</li>
        
      </ul>
      `	},
      {question:'2. Where i can register for the event?', answer:'Go to the register button at the top of the website.'},
      {question:'3. What should I bring to the event?', answer:'Bring Your College ID or CAT/MAT hall tickets (if applicable)'},
      {question:'4. Can I register at the event?', answer:'Yes, you can register at the event.'},
      {question:'5. Can I bring a guest to the event?', answer:'Only parents are allowed to the event.'},
      {question:'6. Does The TICKET TO MBA offer any scholarships?', answer:'Yes the event offers scholarships as per eligibility.'}

    
      
      
      
      ],
      activePanel: null
    }
  }
}
</script>


