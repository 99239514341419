<template>
  <div>    
    <preloader v-if="loading"  />
    <v-snackbar
      v-model="showSnackbar"
      :timeout="3000"
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <uploader />
    <!-- <multi-uploader /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      showSnackbar: false,
      openDialog: false
    }
  },
  computed: {
    ...mapGetters(["loading", "snackbar", "uploading"]),
    snackbarMessage() {
      return this.snackbar? this.snackbar.message : ""
    }
  },
  watch: {
    snackbar: {
      handler(val) { 
        if(this.snackbar) { this.showSnackbar = true }
      },
      deep: true
    },
    showSnackbar(val) {
      if(val == false) { this.$store.commit("SET_SNACKBAR", null) }
    },
    // uploading(val) {
    //   if(val == 100) {
    //     this.$nextTick(() => {
    //       this.$store.commit("SET_UPLOAD_STATE", 0)
    //     })
    //   }
    // }
  }
}
</script>