<template>
  <div style="margin-bottom:56px;">
    <v-app-bar
      color="white"
      eluvation
      fixed    
      > 
        <!-- <v-toolbar-title class="headline text-uppercase mt-1"> -->
          <!-- <v-spacer></v-spacer> -->
         <v-app-bar-nav-icon class="grey-text hidden-sm-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- </v-toolbar-title> -->
       
          <div class="pb-1" :class="{'pa-2':$vuetify.breakpoint.smAndDown}" style="height:56px; width:140px; display:inline-block;" >
            <router-link to="/"><img  src="../assets/logo1.png" style="width:100%;height: 100%;"></router-link>
          </div>
         <!-- <v-toolbar-title v-show="$vuetify.breakpoint.smAndDown">TICKET TO MBA</v-toolbar-title> -->
        <v-spacer></v-spacer>
        <!-- <v-spacer></v-spacer> -->
        <v-toolbar-items class="hidden-xs-only">
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'home'}" to="/" text>Home</v-btn>
          <!-- <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'contact'}" to="/contact" text>MBA Colleges</v-btn> -->
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'event'}" to="/registration/ticket-to-mba" text>Event</v-btn>
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'gallery'}" to="/gallery" text>Gallery</v-btn>
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'about'}" to="/about-us" text>About Us</v-btn>               
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'contact'}" to="/contact" text>Contact</v-btn>
          <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'review'}" to="/review" text>Write Review</v-btn>
          <!-- <v-btn style="font-family:'PT Serif';font-weight:bold;" :class="{'activeTab': $route.name == 'blogs'}" to="/blogs" text>Blog</v-btn> -->
        </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer temporary v-model="drawer" app>
      <v-row>
        <v-col >
          <v-img  src="../assets/logo1.png" class="mx-auto mb-4" style="width:70%;"></v-img>          
        </v-col>
      </v-row>
      <v-list>
<v-divider />        
      <v-list-item to="/">
        <v-list-item-content >
            <v-list-item-title >Home</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      <v-list-item to="/registration/ticket-to-mba">
        <v-list-item-title>Event</v-list-item-title>
      </v-list-item>
      <v-list-item to="/gallery">
        <v-list-item-title>Gallery</v-list-item-title>
      </v-list-item>
      <v-list-item to="/contact">
        <v-list-item-title>Contact</v-list-item-title>
      </v-list-item>
      <v-list-item to="/about-us">
        <v-list-item-title>About Us</v-list-item-title>
      </v-list-item>
      <v-list-item to="/review">
        <v-list-item-title>Write Review</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item to="/blogs">
        <v-list-item-title>Blog</v-list-item-title>
      </v-list-item>
      <v-list-item to="/blogs">
        <v-list-item-title>MBA College</v-list-item-title>
      </v-list-item> -->
      <!-- <v-list-item to="/blogs">
        <v-list-item-title>Blog</v-list-item-title>
      </v-list-item> -->
    </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>

export default {
  data: () => ({
    opacity: 0,
    drawer: false
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {    
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.opacity = currentScrollPosition * 0.02;
    }
  },
  beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
  } 
}
</script>

<style scoped>
.activeTab{
  border-bottom: 3px solid #1678B1;
  color:#1678B1;
}
.v-image__image--cover {
    background-size: contain !important;
}
</style>