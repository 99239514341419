<template>
  <div>
    <Carousel />
    <Introduction />
    <Events />
    <InstituteList />
    <Benefits />
    <Exhibitors />
    <Faq />
    <Video />
    <Testimonial />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/Carousel.vue'
import Introduction from '@/components/Introduction.vue'
import Events from '@/components/Events.vue'
import InstituteList from '@/components/InstituteList.vue'
import Benefits from '@/components/Benefits.vue'
import Exhibitors from '@/components/Exhibitors.vue'
import Faq from '@/components/Faq.vue'
import Video from '@/components/Video.vue'
import Testimonial from '@/components/Testimonial.vue'

export default {
  name: 'home',
  components: {
    Carousel,
    Introduction,
    Events,
    InstituteList,
    Benefits,
    Exhibitors,
    Faq,
    Video,
    Testimonial
  }
}
</script>
