import { render, staticRenderFns } from "./Benefits.vue?vue&type=template&id=649cd1aa&scoped=true&"
import script from "./Benefits.vue?vue&type=script&lang=js&"
export * from "./Benefits.vue?vue&type=script&lang=js&"
import style0 from "./Benefits.vue?vue&type=style&index=0&id=649cd1aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649cd1aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VContainer,VFlex,VHover,VLayout})
