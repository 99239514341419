<template>
  <div class="white py-5">
    <v-container class="mt-5 pt-5" grid-list-md>
      <v-layout row wrap>
        <v-flex class="text-center pb-5" xs12 data-aos="fade-up" data-aos-duration="1200">
          <h2 class="app-display-1 secondary--text font-weight-light" style="letter-spacing:3px !important;">
            INSTITUTES
          </h2>
          <div class=" grey--text text--darken-1  mt-2" style="letter-spacing:1px;font-family:Noto Serif">
            Institutions participating in the Event.
          </div>         
        </v-flex>
      </v-layout>
      <v-layout class="mt-3" row justify-center align-center wrap>
        <v-flex v-for="institute in institutes" 
          :key="institute.name"
          class="text-center mt-4 py-5" 
          xs6 md2 
          data-aos="fade-up" 
          data-aos-duration="1000" >
           <div style="height:100px; width:120px;border-radius:20px;overflow:hidden; margin:0 auto;">
              <img :src="institute.logo" style="height:100%; width:100%; object-fit:contain"/>
           </div>
          <div class="body-1 mt-4" style="font-weight:900; font-family:'PT Serif';">
            {{ institute.name }}
          </div>     
         
        </v-flex>
      </v-layout>
    </v-container>    
  </div>
</template>

<script>
export default {
  data(){
    return{
      institutes:[
        {logo: require('@/assets/institutes/4.0/1.png'), name: 'Institute For Technology And Management'},
        {logo: require('@/assets/institutes/4.0/2.png'), name: 'Asian Business School'},
        {logo: require('@/assets/institutes/4.0/3.png'), name: 'Acharya'},
        {logo: require('@/assets/institutes/4.0/4.png'), name: 'ABBS'},
        {logo: require('@/assets/institutes/4.0/5.png'), name: 'UPES University'},
        {logo: require('@/assets/institutes/4.0/6.png'), name: 'MIT World Peace University'},
        {logo: require('@/assets/institutes/4.0/7.png'), name: 'ISMS Pune'},
        {logo: require('@/assets/institutes/4.0/8.png'), name: 'Parul University'},
        {logo: require('@/assets/institutes/4.0/9.png'), name: 'IIEBM'},
        {logo: require('@/assets/institutes/4.0/10.png'), name: 'Sharda University'},
        {logo: require('@/assets/institutes/4.0/11.png'), name: 'SRM University'},
        {logo: require('@/assets/institutes/4.0/12.png'), name: 'We School'},
        {logo: require('@/assets/institutes/4.0/13.png'), name: 'Yangpoo ClaaS'},
        {logo: require('@/assets/institutes/4.0/14.png'), name: 'ATLAS University'},
        // {logo: require('@/assets/institutes/AsiaPacific.jpg'), name: 'Asia Pacific Institute of Management'},
        // {logo: require('@/assets/institutes/FIIB.png'), name: 'FIIB'},
        // {logo: require('@/assets/institutes/gbs-logo.png'), name: 'Globsyn Business School'},
        // {logo: require('@/assets/institutes/lexicon.jpg'), name: 'Lexicon Management Institute'},
        // {logo: require('@/assets/institutes/ISBR.png'), name: 'ISBR Business School'},
        // {logo: require('@/assets/institutes/BIBS.jpg'), name: 'Bengal Institute of Business Studies'},
        // {logo: require('@/assets/institutes/DMTIMS LOGO.jpg'), name: 'DMTIMS'},
        // {logo: require('@/assets/institutes/EIILM.jpg'), name: 'EIILM'},
        // {logo: require('@/assets/institutes/fostiima.jpg'), name: 'FOSTIIMA Business School'},
        // {logo: require('@/assets/institutes/GNIOT LOGO.jpg'), name: 'Greater Noida Institute of Technology'},
        // {logo: require('@/assets/institutes/IMM.jpeg'), name: 'IMM'},
        // {logo: require('@/assets/institutes/IILM.jpg'), name: 'IILM'},
        // {logo: require('@/assets/institutes/iibs_logo.jpg'), name: 'International Institute of Business Studies'},
        // {logo: require('@/assets/institutes/ismr.jpg'), name: 'International School of Management & Research'},
        // {logo: require('@/assets/institutes/izee.jpg'), name: 'IZEE'},
        // {logo: require('@/assets/institutes/kcm.jpg'), name: 'Karnataka College of Management'},
        // {logo: require('@/assets/institutes/piet.png'), name: 'P.I.E.T'},
        // {logo: require('@/assets/institutes/parul.jpeg'), name: 'Parul University'},
        // {logo: require('@/assets/institutes/praxis.png'), name: 'Praxis Business School'},
        // {logo: require('@/assets/institutes/presidency.jpg'), name: 'Presidency University'},
        // {logo: require('@/assets/institutes/riim.jpg'), name: 'RIIM'},
        // {logo: require('@/assets/institutes/sbs.jpg'), name: 'Shanti Business School'},
        // {logo: require('@/assets/institutes/sunstone.jpg'), name: 'Sunstone Eduversity'},
        // {logo: require('@/assets/institutes/UNITEDWORLDSCHOOLOFBUSINESS.jpg'), name: 'Unitedworld School of Business'}
        
        //OLD SCHOOL
        
        // {logo: require('@/assets/institutes/AJEENKYA.png'), name: 'Ajeenkya University'},
        // {logo: require('@/assets/institutes/GLBAJAJ.jpg'), name: 'Gl Bajaj'},
        // {logo: require('@/assets/institutes/IEM.png'), name: 'IEM'},
        // {logo: require('@/assets/institutes/KL.png'), name: 'KL University'}
        // {logo: require('@/assets/institutes/AIMS.png'), name: 'AIMS'},
        // {logo: require('@/assets/institutes/DOONBUSINESSSCHOOL.png'), name: 'Doon Business School'},
        // {logo: require('@/assets/institutes/ITEMUNIVERSITY.png'), name: 'Item University'},
        // {logo: require('@/assets/institutes/ITMGROUPOFINSTITUTIONS.png'), name: 'ITM Group of Institutions'},
        // {logo: require('@/assets/institutes/jisulogo.png'), name: 'Jis University'},
        // {logo: require('@/assets/institutes/MODYUNIVERSITY.png'), name: 'Mody University'},
        // {logo: require('@/assets/institutes/NSHM.jpg'), name: 'NSHM'},
        // {logo: require('@/assets/institutes/rrinstitution.jpg'), name: 'RR Institution'},
        // {logo: require('@/assets/institutes/sanjivani_group_logo_.png'), name: 'Sanjivani Group of Institution'},
      ]
    }
  }
}
</script>

