<template>
  <div v-show="!loading">
    <v-container grid-list-xs>
      <v-row>
        <v-col cols="12" class="text-center app-headline my-4">
        <v-chip
          large
          class="ma-2 px-3"      
        >      
          <v-avatar left>
            <v-icon>mdi-thumb-up</v-icon>
          </v-avatar>            
          Total Reviews: {{ reviews.length }}
        </v-chip>
        </v-col>
        <v-col v-for="(review,index) in reviews" md="3" :key="`review-${index}`">          
          <v-card outlined>
            <v-card-text class="text-center">
              <div class="mb-3">
                <v-rating
                    :value="review.rating"
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                ></v-rating>
              </div>
              <div style="font-size:16px;line-height:1.5;">
                "{{ review.text }}"
              </div>
              <v-divider class="my-4" />
              <div>
                {{ review.name }} <br>
                {{ review.email }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { listReviews } from "@/models/Review.js"

export default {
  data: () => ({
    reviews: []
  }),
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  },
  mounted() {
    this.$store.commit("SET_LOADING_STATE", true)
    listReviews()
    .then(reviews => {
      console.log(reviews)
      this.reviews = reviews
    })
    .catch(e => {
      this.$store.commit("SET_SNACKBAR", { message: "Failed to load reviews" })
    })
    .finally(() => {
      this.$store.commit("SET_LOADING_STATE", false)
    })
  }
}
</script>