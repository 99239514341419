import Vue from 'vue'
import Vuex from 'vuex'
import { addPerson, getPersonsCollection, getPersonByField } from "./models/Person"
import { authActions } from './services/Auth'
// import { getBlogsCollection } from "./models/Blogs"
import _ from "lodash"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authData: {},    
    currentPerson: {},
    blogsCollection: [],
    personsCollection: [],
    loading: false,
    uploading: false,
    uploadInfo: {
      url: ""      
    },
    upload: {
      active: false,
      url: '',
      name: ''
    },
    snackbar: null,
    multiUpload: {
      active: false,
      preview: undefined,
      currentCount: 0,
      callback: () => {}
    },
    routerKey: 1
  },
  getters: {    
    authData(state) {
      return state.authData
    },        
    routerKey(state) {
      return state.routerKey
    },
    loading(state) {
      return state.loading
    },
    uploading(state) {
      return state.uploading
    },
    uploadInfo(state) {
      return state.uploadInfo
    },    
    multiUpload(state) {
      return state.multiUpload
    },
    person(state) {
      return state.person
    },
    personsCollection(state) {
      // return state.personsCollection
      return _.sortBy(state.personsCollection, o => o.timestamp)
    },    
    snackbar(state) {
      return state.snackbar
    },
  },
  mutations: {
    SET_AUTH_DATA(state, data) {
      state.authData = data
      Vue.prototype.$authData = data      
    },    
    TOGGLE_ROUTER_KEY(state) {
      state.routerKey = -state.routerKey
    },
    SET_CURRENT_PERSON(state, data) {
      state.currentPerson = data
    },       
    SET_BLOGS_COLLECTION(state, data) {
      state.blogsCollection = data
    },
    SET_LOADING_STATE(state, data) {
      state.loading = data
    },
    SET_MULTI_UPLOAD_STATE(state, data) {
      if(data.reset) {
        state.multiUpload = {
          active: false,
          preview: undefined,
          currentCount: 0,
          callback: () => {}          
        }
      }
      state.multiUpload = _.merge(state.multiUpload, data)
    },    
    SET_PERSONS_COLLECTION(state, data) {
      state.personsCollection = data
    },    
    SET_UPLOAD_STATE(state, data) {
      state.uploading = data
    },
    SET_UPLOAD_INFO(state, data) {
      state.uploadInfo = data
    },
    SET_SNACKBAR(state, data) {
      state.snackbar = data
    }
  },
  actions: {      
    signIn({ commit }, credentials) {
      return authActions.signIn(credentials)
        .then(user => {
          commit("SET_AUTH_DATA", user)
          return Promise.resolve(user)
        })
    },
    signOut({ commit }) {
      return authActions.signOut()
        .then(() => {          
          // TODO - RESET USER PERSON
          commit("SET_CURRENT_PERSON", blankPerson())
          return Promise.resolve()
        })
    },    
    addPerson({ }, person) {
      return getPersonByField('phone', person.phone)
        .then(res => {         
          if(res !== false) { return Promise.resolve(false) } // if there is a previous match
          return addPerson(person)
            .then(() => {              
              // commit("SET_CURRENT_PERSON", person)
              return Promise.resolve(true)
            })
            .catch(e => {
              console.log(e)
              return Promise.resolve(e)
            })          
        })
    },
    getPersonsCollection({ commit }) {             
      return getPersonsCollection()
        .then(data => {
          commit("SET_PERSONS_COLLECTION", data) 
          return Promise.resolve(data)
        })
        .catch(err => Promise.reject(err))
    },    
    getBlogsCollection({ commit }, blogId) {
      return getBlogsCollection
        .then(data => {
          commit("SET_BLOGS_COLLECTION", data)
          return Promise.resolve(data)
        })
    }
  }
})

function blankPerson() {
  return {

  }
}