<template>
<div class="dashboard-login fill-height">
  <v-container
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12 pb-2">
          <v-card-title style="margin:auto -4px;" class="text-center pt-0" primary-title>
            <v-layout class="mb-5" row wrap>
              <v-flex class="grey lighten-4" xs12>
                  <img class="mt-4" src="../assets/logo1.png" width="150">                
              </v-flex>
              <v-flex class="mt-5" xs12>
                <h4 class="grey--text text--darken-3 font-weight-light">Sign in to your account</h4>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Login"
                name="login"
                v-model="credentials.email"
                prepend-inner-icon="mdi-account"
                outlined                
                type="text"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Password"
                name="password"
                v-model="credentials.password"
                prepend-inner-icon="mdi-lock"
                outlined                
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container class="pa-0" grid-list-xs>  
              <v-layout row wrap>
                <v-flex>                  
                  <v-btn depressed :loading="signingIn" @click="signIn()" style="margin-top:-20px;" block color="primary">Login</v-btn>
                </v-flex>
              </v-layout>            
            </v-container>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
import { triggerAction } from "@/services/common"

export default {
  data: () =>({
    signingIn: false,
    credentials: {
      email: "",
      password: ""
    }
  }),    
  methods: {      
      signIn() {       
        this.signingIn = true           
        triggerAction("signIn", this.credentials, false)
        .then(user => {                    
            this.$store.commit("SET_SNACKBAR", { message: "Logged in successfully" })         
            this.$router.replace("/dashboard")
        })
        .catch(err => {
            this.signingIn = false
            this.$store.commit("SET_SNACKBAR", { message: "Wrong email and password combination." })                                                                 
            console.log(err)
        })
      },
  }    
}
</script>

<style scoped>
  .dashboard-login {
    background: url('https://stmed.net/sites/default/files/blue-white-wallpapers-25171-712462.jpg');
    background-size: cover;    
  }
</style>