<template>
    <div>
      <!-- <p>Ola world .. this is MEXIIIICOO</p> -->
        <Registration 
            :eventId="eventId"
            :eventName="eventName"
            :eventDesc="eventDesc"
        >
        <!-- <template v-slot:eventDesc>
            <p>
                The aspirants will be able to get an opportunity for interacting with the candidates who also will join this event. The management awareness program is all about helping the colleges and University is that help various aspects of MBA programs. The students who are looking to crack competitive exams like CAT and MAT can maximize the benefits of this event.
            </p>  
        </template> -->
        </Registration>
    </div>
</template>


<script>
import Registration from '@/components/Registration'
export default {
    components:{
        Registration
    },
    data: () => ({
        eventId: "Some Id",
        eventName: "Management Awareness Program",
        eventDesc: '<p>The aspirants will be able to get an opportunity for interacting with the candidates who also will join this event. The management awareness program is all about helping the colleges and University is that help various aspects of MBA programs. The students who are looking to crack competitive exams like CAT and MAT can maximize the benefits of this event.</p>'
    })
}
</script>