<template>
    <div>
        <v-dialog             
            :value="showDialog"
            fullscreen hide-overlay transition="slide-y-reverse-transition"            
            >
            <v-card>
                <v-toolbar>
                    <v-btn icon @click="$emit('closeDialog')">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                </v-toolbar>                
                <v-container grid-list-md>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <slot></slot>
                        </v-flex>
                    </v-layout>
                </v-container>                 
            </v-card>          
        </v-dialog>        
    </div>
</template>

<script>

export default {
    props: {
        showDialog: {
            default: false
        },
        imgUrl: {
            default: undefined
        },
        title: {
            default: "Preview"
        }
    },
    computed: {
    },
    watch: {
    }    
}
</script>