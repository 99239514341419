    <template>
    <div>
        <v-container fluid grid-list-md class="pt-0">    
            <Herosection 
                header="Registration" 
                subheading="Register yourself for the event" 
                background="registration1.jpg"
            />
        </v-container>
        <v-container grid-list-lg>
            <v-layout row wrap>
                <v-flex md7>
                    <h2 class="app-headline header-title font-weight-light my-5 pl-5" style="border-left:5px solid #f37736;"> &nbsp; &nbsp; {{ eventName }}</h2> 
                    <p>
                        <span v-html="eventDesc">
                        </span>
                    </p>                    
                </v-flex>
                <v-flex md5>
                <v-card style="margin-top:-60px;" class="pa-4" raised :class="{'mt-5':$vuetify.breakpoint.smAndDown}">
                    <v-card-title class="mb-5 title">
                        Please enter your details for the event
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="valid" lazy-validation ref="form">
                        <v-text-field
                            prepend-inner-icon="mdi-account"
                            v-model="personInfo.name"
                            label="Name*"
                            outlined
                            :rules="nameRules"
                            required
                        ></v-text-field>
                         <v-text-field
                            prepend-inner-icon="mdi-school"
                            v-model="personInfo.college"
                            label="College*"
                            outlined
                            required
                            :rules="collegeRules"                            
                        ></v-text-field>
                        <v-select
                            prepend-inner-icon="mdi-notebook"
                            :items="items"
                            label="Semester*"
                            v-model="personInfo.semester"
                            :rules="semesterRules"
                            outlined
                            required
                        ></v-select>
                        <v-select
                            prepend-inner-icon="mdi-pencil"
                            :items="['CAT', 'SNAP', 'MAT & OTHER EXAMS']"
                            label="Competitive Exams"
                            v-model="personInfo.competitiveExams"                            
                            outlined
                            required
                        ></v-select>
                        <!-- <v-text-field
                            v-if="personInfo.semester == 'Others'"
                            prepend-inner-icon="mdi-pencil"
                            label="Other semester name"
                            v-model="otherSemesterName"
                            dense
                            outlined
                            :rules="semesterRules"
                            required
                        ></v-text-field>     -->
                        <v-select
                            prepend-inner-icon="mdi-book-open-page-variant"
                            :items="['B.COM', 'BBA', 'B.TECH', 'BA', 'BSC', 'BCA & Others']"
                            label="Course*"
                            v-model="personInfo.course"                            
                            outlined
                            required
                        ></v-select>                                            
                        <v-text-field
                            prepend-inner-icon="mdi-phone"
                            v-model="personInfo.phone"
                            label="Phone No*"
                            outlined
                            type="number"
                            :rules="numberRules"
                            required
                        ></v-text-field>
                        <v-text-field
                            prepend-inner-icon="mdi-email"
                            v-model="personInfo.email"
                            label="E-mail*"
                            outlined
                            :rules="emailRules"
                            required
                        ></v-text-field>        
                        <v-select
                            label="Source*"
                            prepend-inner-icon="mdi-comment-processing"
                            placeholder="From where did you come to know about us?"
                            v-model="personInfo.refferedBy"
                            :rules="sourceRules"
                            :items="['Premier Edu', 'Veniu', 'Social Media', 'Others']"                                                        
                            outlined
                            required
                        ></v-select>    
                        <v-text-field
                            v-if="personInfo.refferedBy == 'Others'"
                            prepend-inner-icon="mdi-pencil"
                            label="Other source name"
                            v-model="otherSourceName"
                            dense
                            outlined
                            :rules="sourceRules"
                            required
                        ></v-text-field>                                                                
                        <!-- <v-textarea
                            prepend-inner-icon="mdi-pencil"
                            rows="2"
                            v-model="personInfo.comment"
                            label="Add a comment"
                            outlined
                        ></v-textarea>                  -->
                        </v-form>                                                      
                        <div>
                            Fields marked with * are required.
                        </div>                    
                    </v-card-text>          
                    <v-card-actions>
                        <v-btn @click="addPerson" color="primary" dark depressed block>SUBMIT</v-btn>
                    </v-card-actions>                   
                </v-card>           
                </v-flex>                
            </v-layout>
        </v-container>
        <Footer />
        <v-dialog width="400" v-model="personAddedModalStatus">
            <v-card class="py-3" >
                <v-card-title primary-title>
                   <v-icon>mdi-check</v-icon> &nbsp; Registration Successful!
                </v-card-title>
                <v-card-text>
                    Please check your email for details.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="400" v-model="imgUploadedModalStatus">
            <v-card class="pt-2" >
                <v-card-title class="app-title" primary-title>
                   <v-icon>mdi-check</v-icon> &nbsp; UPLOAD SUCCESSFUL;
                </v-card-title>
                <v-divider />
                <v-card-text class="py-5">
                    <v-img height="200" contain :src="currentImg.url" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="handleUploadModify(currentImg.type)" class="ma-2" text>CHANGE</v-btn>
                    <v-btn @click="imgUploadedModalStatus = false" class="ma-2" depressed color="primary">DONE</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
            
    </div>
</template>


<script>

import { triggerAction, generateRegistrationId } from "@/services/common"
import Herosection from '@/components/Herosection'
import { mapGetters } from "vuex"

export default {
    props: [
        "eventId",
        "eventName",
        "eventDesc"
    ],
    components:{
        Herosection
    },
    created() {
        this.resetPerson()
    },
     data: () => ({
      personInfo: {},      
      valid: true,
    //   isOtherSemester: false,
      otherSemesterName: "",      
      otherSourceName: "",
      personAddedModalStatus: false,
      imgUploadedModalStatus: false,      
      uploads: [],
      currentImg: {},
      email: '',
      nameRules:[
          v => !!v || 'Name is required'
      ],
      courseRules:[
          v => !!v || 'Course is required'
      ],
      sourceRules:[
          v => !!v || 'Source is required'
      ],
      semesterRules:[
          v => !!v || 'Semester is required'
      ],
      competitiveExamsRules:[
          v => !!v || 'Competitive Exams is required'
      ],
      collegeRules:[
          v => !!v || 'College is required'
      ],            
      numberRules: [
        v => !!v || 'Number is required',        
        v => (v.length>0 && v.length == 10) || 'Number must be of 10 characters',
      ],
      emailRules: [
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        v => !!v || 'E-mail is required',
      ],
      items: ['5th Semester','6th Semester','Graduate','PG'],
    }),
    computed: {
        ...mapGetters(['uploadInfo']),
    },
    methods: {
        // truncatedImgName(imgType) {
        //     let img = this.uploads.find(img => img.type == imgType)            
        //     return img.name.length > 15 ? `${img.name.slice(0,15)}...` : img.name
        // },                
        onUploaded(data, type) {
            this.uploads.push({ ...data, type })
            this.imgUploadedModalStatus = true
            this.currentImg = { ...data, type }
            this.personInfo[type] = data.url
        },
        handleUploadModify(imgType) {
            this.personInfo[imgType] = ""
            this.imgUploadedModalStatus = false
            // this.$nextTick(() => {
            //     this.$refs[imgType].click()
            // })
        },
        addPerson() {
            const res = this.$refs.form.validate()
            let _personInfo = {
                ...this.personInfo, 
                timestamp: Date.now(),
                year: 2022,
                registrationId: generateRegistrationId()
            }         
            // if(_personInfo.semester == 'Others') {
            //     _personInfo.semester = this.otherSemesterName
            // }
            if(_personInfo.refferedBy == 'Others') {
                _personInfo.refferedBy = this.otherSourceName
            }            

            if(res) {                                 
                triggerAction('addPerson', _personInfo)
                    .then(res => {                        
                        if(res) {
                            this.personAddedModalStatus = true                                 
                            this.$refs.form.reset()
                        } else {                            
                            this.$store.commit("SET_SNACKBAR", { message: "Entered mobile number already exists" })                            
                        }
                    })
            } else {
                this.$store.commit("SET_SNACKBAR", { message: "Errors are present. Please review your entered details." })
            }
        },
        handleError(data){
            this.$store.commit("SET_SNACKBAR", { message: data.msg })
        },
      handleUpload() {
        // this.$store.commit("SET_UPLOAD_STATE", true)
      },
      resetPerson() {
        this.personInfo = {
          name: "",
          eventName: this.eventName,
          college: "",
          semester: "",
          course: "",
          phone: "",
          email: "",
          address: "",
          food: "veg",
          refferedBy: "",
        //   comment: "",
          profileImg: "",
          photoId: "",
          present: false,
          competitiveExams: ""
        }         
      }        
    },
    watch: {
        imgUploadedModalStatus(val) {
            if(val == false) {
                this.currentImg = {}
            }
        }
    }
}
</script>
