import { reviewsRef } from "../services/Database";

export function addReview(review) {
  return reviewsRef
    .add(review)
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export function listReviews() {
  let result = []  
  return reviewsRef
    .get()
    .then(snaps => {
      snaps.forEach(snap => result.push(snap.data()))      
      return Promise.resolve(result)
    })
    .catch(err => Promise.reject(err))  
}