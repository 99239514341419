import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Event from '../views/Event.vue'
import Blogs from '../views/Blogs.vue'
import Gallery from '../views/Gallery.vue'
import Contact from '../views/Contact.vue'
import AboutUs from '../views/Aboutus.vue'
import EventOne from '../views/EventOne.vue'
import EventTwo from '../views/EventTwo.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Review from '../views/Review.vue'
import ViewReviews from '../views/ViewReviews'
import Test from '../views/Test'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/event',
    name: 'event',
    component: Event
  }, 
  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs
  }, 
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },        
  {
    path: '/review',
    name: 'review',
    component: Review
  },        
  {
    path: '/view-reviews',
    name: 'view reviews',
    component: ViewReviews,
    meta: {
      requiresAuth: true
    }    
  },        
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },        
  {
    path: '/about-us',
    name: 'about',
    component: AboutUs
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },   
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresGuest: true
    }      
  },  
  {
    path: '/registration/ticket-to-mba',
    name: 'event one',
    component: EventOne
  }, 
  {
    path: '/registration/management-awareness-program',
    name: 'event two',
    component: EventTwo
  },                     
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})  


export default router
