<template>
  <div>
    <v-img v-if="disableScroll" :height="height" :src="require(`../../assets/${src}`)" >
      <v-container class="pa-0" fill-height grid-list-xs>
        <v-layout class="ma-0" fill-height row align-center wrap >
          <slot></slot>
        </v-layout>
      </v-container>
    </v-img>
    <v-parallax v-else :height="height" :src="require(`../../assets/${src}`)">
      <slot></slot>
    </v-parallax>
  </div>
</template>

<script>
export default {
  props: {
    disableScroll: {
      type: Boolean,
      default: false
    },
    height: {
      default: 500
    },
    src: {
      default: undefined
    }
  }
}
</script>

