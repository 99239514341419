<template>
<div>    
    <v-carousel
    cycle
    height="500"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="slide in slides"
      :key="slide.id"
      :src="slide.img" 
    >
        <v-row
          class="fill-height d-flex justify-center align-center"
        >
        <v-col class="text-center mx-0 auto" cols="12">
          <div style="text-shadow: 1px 1px 2px #9e9e9e;" v-bind:style="{fontSize: headerFontSize + 'px' }" v-html="slide.text"></div>
                  <!-- <h1 class="white--text font-weight-bold" :class="{'display-4':$vuetify.breakpoint.mdAndUp, 'display-3-large':$vuetify.breakpoint.smAndDown}">TICKET
                   TO <span style="color:#fcef66;">MBA</span>.</h1> -->
                  <div class="mt-3 font-weight-light white--text" :class="{'display-1':$vuetify.breakpoint.mdAndUp, 'headline':$vuetify.breakpoint.smAndDown}" style="text-shadow: 1px 1px 2px #9e9e9e;">
                    &nbsp; The Power of <span class="font-weight-bold" style="color:	#fcef66;">Knowledge</span>
                  </div>
                    <v-btn depressed dark class="mx-4 mt-5 flash-button font-weight-bold" x-large :class="{'mt-5':$vuetify.breakpoint.smAndDown}" color="#f37736" href="#register">REGISTER NOW</v-btn>
                    <div class="mt-5">
                      <p class="px-4 pb-3 mt-5 d-inline-block pt-4 white--text carousel-footer" :style="{ fontSize: $vuetify.breakpoint.mdAndUp? '20px' : '18px' }" >6<sup>th</sup> Jan 2022 (10 AM Onwards) &middot; Hotel Lily, Six Mile, Guwahati</p>
                    </div>
              </v-col>
        </v-row>
     
    </v-carousel-item>
  </v-carousel>
</div>
    
</template>

<script>
  export default {
    data () {
      return {
         model: 0,
        colors: [
          'primary',
          'secondary',
          'yellow darken-2',
          'red',
          'orange',
        ],
        slides:[
        {
          img: require('@/assets/carousel/slide1.jpg'),
          text:'<div style="color:white; ">Welcome To <span style="color:#F9EA66;">TICKET TO MBA</span></div>'
        },
        {
          img: require('@/assets/carousel/slide2.jpg'), 
          text: '<div style="color:white; ">Explore the possibilities after <span style="color:#F9EA66;">MBA</span></div>'
        },
        {
          img: require('@/assets/carousel/slide3.jpg'),  
          text: '<div style="color:white; ">Get Upto INR 50,000 <span style="color:#F9EA66;">SCHOLARSHIP</span></div>'
        },
      ]
      }
    },

    computed:{
    headerFontSize(){
      return this.$vuetify.breakpoint.smAndDown ? 42 : 62;
    }
  }
    
    // methods: {
    //    doSomething(val) {
    //      console.log("Logged")
    //    }
    // },
    // mounted() {
    // }
  }
</script>

<style scoped>
.flash-button{
	
	animation-name: flash;
	animation-duration: 1s;
	/* animation-timing-function: linear; */
	animation-iteration-count: infinite;

	/* //Firefox 1+ */
	-webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	/* -webkit-animation-timing-function: linear; */
	-webkit-animation-iteration-count: infinite;
	/* //Safari 3-4 */
	-moz-animation-name: flash;
	-moz-animation-duration: 1s;
	/* -moz-animation-timing-function: linear; */
	-moz-animation-iteration-count: infinite;
}

@keyframes flash {
  0%, 100% {
      background: #FD690D;
      color: rgb(63, 62, 62);
  }
  50% {
      background: #fcef66;
      color: rgb(63, 62, 62);
  }
}

.down-arrow{
  position:absolute;
  animation: 3s arrow infinite ease;
  -webkit-animation: 3s arrow infinite ease;
}

.display-3-large {
  font-size: 4.5rem !important;
  font-weight: 300;
  line-height: 5rem;
  letter-spacing: -0.0083333333em !important;
  font-family: "Roboto", sans-serif !important;  
}

.carousel-footer {
  background-image:linear-gradient(60deg, #1678B1 0%, #389fcf 100%);
  letter-spacing: 2px;   
  border-radius: 12px;
}

@media(max-width:480px) {
  .carousel-footer {
    border-radius: 0;    
  }
}

@keyframes arrow{
        0%,100%{
            top: 10px;
        }
        50%{
            top: 50px;
        }
    }
</style>