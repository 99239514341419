<template>
    <div>
        <v-container fluid grid-list-xs fill-height class="pa-0">
            <v-row fill-height>
                <v-col cols="12" class="pa-0">
                    <div style="height:35vh;">
                        <v-img aspect-ratio="5" :src="require(`../assets/${background}`)" height="100%" width="100%">
                            <v-container grid-list-xs fill-height > 
                                <v-row fill-height>
                                <v-col cols="12">
                                    <h2 class="display-3 mt-3  font-weight-bold white--text" style="text-shadow: 1px 1px 3px #9e9e9e;">{{ header }}</h2>
                                    <div class="white--text font-weight-medium mt-2 body-1" style="letter-spacing:1px;text-shadow: 1px 1px 3px #9e9e9e;">
                                        <v-layout row wrap>                                            
                                            &nbsp;<span class="ml-1" style="font-size:38px; ">&middot;</span> <span class="pl-2">{{ subheading }}</span>
                                        </v-layout>
                                    </div>
                                </v-col>
                                </v-row>
                            </v-container>
                            <!-- <v-overlay 
                                absolute
                                color="#1678B1"
                                opacity="0.3"
                                z-index="-1"
                            />                             -->
                        </v-img>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props:{
        header:{
            default: 'the hero',
            type: String
        },
        background:{
            default: "gallery-hero.jpg",
            type: String,
            required:false
        },
        subheading:{
            default: 'Wonderful subheading here',
            type:String
        }
    }
}
</script>\


<style scoped>
.back{
    background-image:  linear-gradient(to right, rgba(0, 116, 178, 0.637)), url('https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80');
    background-position: center;
    background-size: cover;
}
</style>