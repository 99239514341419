<template>
    <div class="mt-5 pt-5">
        <!-- TESTIMONIALS -->
       
            <v-container class="pt-5" fluid grid-list-md>
                <v-row class="mt-5" row wrap>        
                    <v-col class="text-center pb-4" xs12 data-aos="fade-up" data-aos-duration="1200">
                     <h2 class="app-display-1 secondary--text font-weight-light" style="letter-spacing:3px !important;">
						TESTIMONIALS
					</h2>
					<div class="grey--text text--darken-1 mt-2" style="letter-spacing:1px; font-family:Noto Serif">
						See what our students have to say
					</div> 
                    </v-col>           
                </v-row>
                <v-row>
                    <v-col cols="12"  data-aos="fade-in"
            data-aos-duration="1200"   
            data-aos-delay="500" >
                        <carousel id="customers-testimonials" class="owl-carouselly" :items="3" :autoplay="true" :nav="false" :dots="false" :center="true" :loop="true" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},900:{items:3,nav:false}}">
                                <!--TESTIMONIAL 1 -->
                        <div class="item" v-for="testimonial in testimonials" :key="testimonial.name">
                        <div class="shadow-effect">
                            <img class="img-circle pa-1" style="border-radius:50%;margin-top:-50px;border:3px solid #1678B1" :src="testimonial.src" alt="">
                            <v-rating
                                :value= 5
                                readonly
                                background-color="orange lighten-3"
                                color="orange"
                            ></v-rating>
                            <p class="mt-2">{{ testimonial.text }}</p>
                        </div>
                        <div class="testimonial-name">{{ testimonial.name }}</div>
                        </div>
                        <!--END OF TESTIMONIAL 1 --> 
                        </carousel>
                    </v-col>
                </v-row>
            </v-container>
     
    <!-- END OF TESTIMONIALS -->
    </div>
</template>


<script>
import carousel from 'vue-owl-carousel'
export default {
    components: { carousel },
    data(){
        return{
            // rating: 4.5,
            testimonials : [
                {text: '"It was quite good n fair bit of idea is what i got from here about mba n pgdma."', name: 'Hrishikesh Sarma', src: 'https://lh5.googleusercontent.com/-m5hhJfaNYb4/AAAAAAAAAAI/AAAAAAAAAAA/F3895EsWEag/w60-h60-p-rp-mo-br100/photo.jpg' },
                {text: '"MBA fest was acutely knowledgeable and looking forward to meeting them in the near future."', name: 'Ai Moun', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEFF6tCXWJuWRLxP0Ovu785xFo3oiw_kKf0ZRJCIGH0jbIFvo1&s' },
                {text: '"Ticket to mba is the best edu fair in the city that we have ever attend."', name: 'Kangkan Bhattacharya', src: require('@/assets/testimonial/kb.jpg') },
                {text: '"Mba Aspirants should really attend this event.You will get enough knowledge of MBA pgdm courses."', name: 'Mukhramul hussain sarkar', src: require('@/assets/testimonial/mi.jpg') },
                {text: '"We Want Ticket to Mba in every subsequent Year."', name: 'Madhurjya Bhattacharya', src: require('@/assets/testimonial/mb.jpg') },

            ]
        }
    }
    
}
</script>

<style scoped>
.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
	border:1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
    }
    #customers-testimonials .shadow-effect p {
        font-family: inherit;
        font-size: 17px;
        line-height: 1.5;
        margin: 0 0 17px 0;
        font-weight: 300;
    }

    .img-circle{
        border-radius:50%;
    }
    .testimonial-name {
        margin: -24px auto 0;
        display: table;
        width: auto;
        background-image:linear-gradient(60deg, #1678B1 0%, #389fcf 100%);		
        border-bottom: 3px solid rgb(255, 123, 42);						
        padding: 9px 35px;
        border-radius: 100px;
        text-align: center;
        color: #fff;
    }
    #customers-testimonials .item {
        text-align: center;
        padding: 50px;
        opacity: .2;
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    #customers-testimonials .owl-item.active.center .item {
        opacity: 1 !important;
        -webkit-transform: scale3d(1.0, 1.0, 1) !important;
        transform: scale3d(1.0, 1.0, 1) !important;
    }
    .owl-carouselly .owl-item img {
        transform-style: preserve-3d !important;
        max-width: 90px;
        margin: 0 auto 17px;
    }
    #customers-testimonials.owl-carouselly .owl-dots .owl-dot.active span,
    #customers-testimonials.owl-carouselly .owl-dots .owl-dot:hover span {
        background: #015751;
        transform: translate3d(0px, -50%, 0px) scale(0.7);
    }
    #customers-testimonials.owl-carouselly .owl-dots{
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    #customers-testimonials.owl-carouselly .owl-dots .owl-dot{
        display: inline-block;
    }
    #customers-testimonials.owl-carouselly .owl-dots .owl-dot span {
        background: #015751;
        display: inline-block;
        height: 20px;
        margin: 0 2px 5px;
        transform: translate3d(0px, -50%, 0px) scale(0.3);
        transform-origin: 50% 50% 0;
        transition: all 250ms ease-out 0s;
        width: 20px;
    }

    @media only screen and (max-width: 400px) {
         #customers-testimonials .item{
             padding: 30px 10px !important;
         }
    }
</style>