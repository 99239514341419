<template>
  <div class=" pt-5 back">
    <v-container class="pt-5" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex class="text-center pb-4" xs12 data-aos="fade-up" data-aos-duration="1200">
          <h2 class="app-display-1 secondary--text font-weight-light" style="letter-spacing:3px !important;">
            BENEFITS
          </h2>
          <div class="grey--text text--darken-1 mt-2" style="letter-spacing:1px; font-family:Noto Serif">
            Reasons to attend our events
          </div>          
        </v-flex>
      </v-layout>
      <v-layout class="mt-4" row wrap>
        <v-flex 
          md10
          offset-md1
          class="py-5">
          <v-container fluid grid-list-lg>
            <v-layout row wrap>
              <v-flex 
                class="benefit" 
                style="margin-bottom:50px;"
                md4
                v-for="benefit in benefits" 
                :key="benefit.title"
                data-aos="fade-up" 
                data-aos-duration="1000"
                :data-aos-delay="300"                          
                >
                <!-- <v-icon color="accent">mdi-check-circle</v-icon> &nbsp; -->
                <v-hover v-slot:default="{ hover }">
                  <v-card class="mb-3" color="transparent" :elevation="hover ? 20 : 2" height="200">
                <v-card style="margin-top:-20px;" height="220">                                    
                  <v-card-text class="px-0">
                    <div class="px-4 mt-5 grey--text text--darken-3">
                      {{ benefit.description }}
                    </div>                    
                  </v-card-text>
                </v-card>
                
                <v-card class="text-center" color="transparent" flat  height="240" style="margin-top:-240px;" >
                  <div class="font-weight-bold d-inline-block text-truncate px-4" style="font-size:16px; color:white; background-image:linear-gradient(60deg, #1678B1 0%, #389fcf 100%);width:95%; margin: 0 auto;  border-radius:100px; padding:6px; border-bottom: 3px solid #FD690D">
                  {{ benefit.title }}
                  </div>
                </v-card>
                </v-card>
                </v-hover>
              </v-flex>                            
            </v-layout>
          </v-container>     
        </v-flex>
      </v-layout>
    </v-container>    
  </div>
</template>

<script>
export default {
  data(){
    return{
      benefits: [
        {title:'Free Access ', description:'Get 5 free access for GD & PI in the listed B-schools across India.'},
        {title:'Awareness about B-Schools', description:'With the help of TICKET TO MBA event, the aspirants will be aware of the best B-Schools across India.'},
        {title:'Management Conclave ', description:'Attending Management Conclave, the MBA aspirants will be aware of the various possibilities of pursuing an MBA course also about the different specialisation and an opportunity to interact with the MBA experts from the participating colleges.This in return will help the attendees to gain the right knowledge about MBA'},
        // {title:'Easy to Access Platform', description:'Find an easy to access platform for MBA aspirants in North East India'},
        {title:'Explore the possibilities after MBA', description:'Get to know more about the possibilities and opportunities after MBA in the present scenario.'},
        // {title:'Boost Up the Knowledge', description:'The MBA ticket events can boost up the knowledge about MBA courses in the same locality'},
        // {title:'Loan Assistance', description:'Get hassle free educational loan with minimum documentation'}
        {title:'Interactive Platform ', description:'It can work as an interactive platform for both colleges and students.'},
        {title:'Scholarships ', description:'Get scholarships up to  25,000 INR as per eligibility.'}
      ]
    }
  }
}
</script>

<style scoped>
.back{
  background-image: url('../assets/1234.jpg');
  background-position: top left;
}

/* .benefit{
  transition: transform .2s;
}
.benefit:hover{
  transform: scale(1.2);
  cursor: pointer;
} */
</style>