<template>
    <div>
        <v-container fluid grid-list-md class="pt-0">
            <Herosection header="Contact" subheading="Connect with us" background="contact1.jpg" />
        </v-container>
        <v-container grid-list-lg>
            <v-row>
                <v-col cols="12">
                    <v-row>
                         <v-col cols="12" md="6" class="px-3 pt-5 mt-5">
                             <v-card 
                                height="400"
                                width="600"
                                color="grey lighten-2"
                                flat>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.649144477448!2d91.77096701502947!3d26.1755536834505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a599f84e1fdeb%3A0x7acc25cadc501e06!2sPREMIER%20EDU%20-%20Educational%20Consultants!5e0!3m2!1sen!2sin!4v1567407115844!5m2!1sen!2sin" frameborder="0" style="border:0;width:100%; height:100%;" allowfullscreen=""></iframe>
                             </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-4">
                            <h2 class="app-headline header-title font-weight-light my-5 pl-5" style="border-left:5px solid #f37736;"> &nbsp; &nbsp; CONTACT INFORMATION</h2> 
                            
                            <div class="ml-5 pt-3">
                                <span class="d-flex">
                                    <v-icon>mdi-map-marker</v-icon>
                                    <h4 class="ml-3">Address</h4>
                                </span>
                                <p class="grey--text text--darken-1 pl-5 ml-3">
                                H/No.68, 1st Floor, Near - Bylane No. 9 
                                <br>Opposite China Town Restaurant, Rajgarh Rd, Zoo Tiniali,
                                <br> Guwahati, Assam 781003
                                </p>
                                <span class="d-flex">
                                    <v-icon>mdi-phone</v-icon>
                                    <h4 class=" ml-3">Phone number</h4>
                                </span>
                                <p class="grey--text text--darken-1 pl-5 ml-3">+91-917002613762</p>
                                <!-- <span class="d-flex align-center">
                                    <v-icon>mdi-phone-classic</v-icon>
                                    <h4 class="ml-3">Telephone number</h4>
                                </span>
                                <p class="grey--text text--darken-1 pl-5 ml-3">+0361</p> -->
                                <span class="d-flex align-center">
                                    <v-icon>mdi-at</v-icon>
                                    <h4 class=" ml-3">Email</h4>
                                </span>
                                <p class="grey--text text--darken-1 pl-5 ml-4">premier.edu12@gmail.com</p>                                     
                            </div>                         
    
                            <div class="my-5 pt-1">
                                <h2 class="app-headline header-title font-weight-light my-5 pl-5" style="border-left:5px solid #f37736;"> &nbsp; SOCIAL MEDIA LINKS</h2> 
                                <ul class="mt-5 pt-3">
                                    <li>
                                        <a href="https://www.facebook.com/TicketToMbaGuwahati/">
                                            <v-icon color="blue darken-4" x-large>mdi-facebook</v-icon>
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <v-icon color="blue lighten-3" x-large>mdi-twitter-box</v-icon>
                                    </li> -->
                                    <li>
                                        <a href="https://www.instagram.com/tickettombaindia/">
                                            <v-icon color=" #bc2a8d" x-large>mdi-instagram</v-icon>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            
                        </v-col>
                       
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <Footer />
    </div>
</template>

<script>
import Herosection from '@/components/Herosection'
export default {
    components:{
        Herosection
    }
}
</script>

<style scoped>
li{
    list-style: none;
    display: inline-block;
    margin-right: 70px;
}
a{
    text-decoration: none;
}
</style>