<template>
  <div>
    <v-container fluid grid-list-md class="pt-0">
      <Herosection class="mb-5" header="Gallery" subheading="Explore our image gallery" />                
    </v-container>

    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex 
          v-for="gallery in galleryImg" 
          :key="gallery.id"        
          md3 xs6>
          <v-card
            @click="openImage(gallery)"
            flat
            color="grey lighten-3">            
            <v-img aspect-ratio="1.2" color="grey" :src="gallery" />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
    <fullscreen-dialog @closeDialog="showDialog = false" :showDialog="showDialog">
      <v-img class="mt-5" contain max-height="500" :src="previewImgSrc" />
    </fullscreen-dialog>
  </div>
</template>    

<script>
import Herosection from '@/components/Herosection'
import { triggerAction } from "@/services/common"

export default {
  components:{
        Herosection
    },
    data:()=>({
      text: "The myths of the quest started when Perseus the son of Danae and Zeus. It was foretold to Danae’s father, Acrisius(the king of Argos) that Danae’s son would kill him. So Acrisius locked his daughter Danae away in a bronze chamber but Zeus impregnated her anyway by transforming himself in a form of golden spring. Out of fear Acrisius hurled his daughter and his grandson in a wooden chest into the sea. But they were rescued by Dictys and lived in the island of Seriphos.",
      maxLenght: 320,
      previewImgSrc: "",
      showDialog: false,
      galleryImg : [
         require('@/assets/4.0/49.jpg'),
        require('@/assets/4.0/50.jpg'),
        require('@/assets/4.0/51.jpg'),
        require('@/assets/4.0/52.jpg'),
        require('@/assets/4.0/53.jpg'),
        require('@/assets/4.0/54.jpg'),
        require('@/assets/4.0/55.jpg'),
        require('@/assets/4.0/56.jpg'),
        require('@/assets/4.0/57.jpg'),
        require('@/assets/4.0/58.jpg'),
        require('@/assets/4.0/59.jpg'),
        require('@/assets/4.0/60.jpg'),
        require('@/assets/4.0/61.jpg'),
        require('@/assets/4.0/62.jpg'),
        require('@/assets/4.0/30.jpg'),
        require('@/assets/4.0/31.jpg'),
        require('@/assets/4.0/32.jpg'),
        require('@/assets/4.0/33.jpg'),
        require('@/assets/4.0/34.jpg'),
        require('@/assets/4.0/35.jpg'),
        require('@/assets/4.0/36.jpg'),
        require('@/assets/4.0/37.jpg'),
        require('@/assets/4.0/38.jpg'),
        require('@/assets/4.0/39.jpg'),
        require('@/assets/4.0/40.jpg'),
        require('@/assets/4.0/41.jpg'),
        require('@/assets/4.0/42.jpg'),
        require('@/assets/4.0/43.jpg'),
        require('@/assets/4.0/44.jpg'),
        require('@/assets/4.0/45.jpg'),
        require('@/assets/4.0/46.jpg'),
        require('@/assets/4.0/47.jpg'),
        require('@/assets/3.0/1.jpg'),
        require('@/assets/3.0/2.jpg'),
        require('@/assets/3.0/3.jpg'),
        require('@/assets/3.0/4.jpg'),
        require('@/assets/3.0/5.jpg'),
        require('@/assets/3.0/6.jpg'),
        require('@/assets/gallery/1.jpg'),
        require('@/assets/gallery/2.jpg'),
        require('@/assets/gallery/3.jpg'),
        require('@/assets/gallery/4.jpg'),
        require('@/assets/gallery/5.jpg'),
        require('@/assets/gallery/6.jpg'),
        require('@/assets/gallery/7.jpg'),
        require('@/assets/gallery/8.jpg'),
        require('@/assets/gallery/9.jpg'),
        require('@/assets/gallery/10.jpg'),
        require('@/assets/gallery/11.jpg'),
        require('@/assets/gallery/13.jpg'),
        require('@/assets/gallery/14.jpg'),
        require('@/assets/gallery/15.jpg'),
        require('@/assets/gallery/16.jpg'),
        require('@/assets/gallery/17.jpg'),
        require('@/assets/gallery/18.jpg'),
        require('@/assets/gallery/19.jpg'),
        require('@/assets/gallery/20.jpg'),
        require('@/assets/gallery/21.jpg'),
        require('@/assets/gallery/22.jpg'),
        require('@/assets/gallery/23.jpg'),
        require('@/assets/gallery/24.jpg'),
        require('@/assets/gallery/25.jpg'),
        require('@/assets/gallery/26.jpg'),
        require('@/assets/gallery/27.jpg'),
        require('@/assets/gallery/28.jpg'),
        require('@/assets/gallery/29.jpg'),
        
      ]
    }),
    computed:{
      shortText(){
        return this.text.slice(0,this.maxLenght)
      }
    },
    methods: {
      openImage(src) {
        this.previewImgSrc = src
        this.showDialog = true
      },
      addPerson() {
        triggerAction('addPerson', {
          name: "John Doe"
        })
        .then(() => {
          console.log("Added")
        })
      }
    }
}
</script>

<style>
.v-expansion-panel {
  box-shadow: none;
}
</style>