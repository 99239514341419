<template>
    <div>
        <app-parallax :height="parallaxHeight" :disableScroll="$vuetify.breakpoint.smAndDown" src="spotlight.jpg" >
        <v-container grid-list-xs>
            <v-layout row wrap>
              <v-flex class="text-center pb-4" xs12 data-aos="fade-up" data-aos-duration="1000">
                <h2 class="app-display-1 white--text font-weight-light" style="letter-spacing:3px !important;">
                  VIDEO GALLERY
                </h2>
                <div class="grey--text text--lighten-3 mt-2" style="letter-spacing:1px; font-family:Noto Serif">
                  Know us better
                </div>            
              </v-flex>
            </v-layout>
            <v-row data-aos="fade-up" data-aos-duration="1400">
                <!-- <v-col cols="12" md="4" class="" xs12 >
                    <LazyYoutubeVideo class="elevation-7" url="https://www.youtube.com/watch?v=uV8TC14WzKE" />
                </v-col> -->
                <v-col cols="12" md="4">
                    <LazyYoutubeVideo class="elevation-7" url="https://www.youtube.com/watch?v=uV8TC14WzKE" />
                </v-col>
                <v-col cols="12" md="4">
                    <LazyYoutubeVideo class="elevation-7" url="https://www.youtube.com/watch?v=pYoT80Gv5So" />
                </v-col>
                <v-col cols="12" md="4">
                    <LazyYoutubeVideo class="elevation-7" url="https://www.youtube.com/watch?v=vAMSMj88bG8" />
                </v-col>
                <!-- <v-col cols="12" md="4">
                     <LazyYoutubeVideo class="elevation-7" url="https://www.youtube.com/watch?v=uV8TC14WzKE" />
                </v-col> -->
            </v-row>
        </v-container>
        <v-overlay 
        absolute
        color="primary"
        opacity="0.3"      
        z-index="-1"
        />  
        </app-parallax>
    </div>
</template>


<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video'

export default {
  components: {
    LazyYoutubeVideo
  },  
  computed:{
    parallaxHeight(){
     if(this.$vuetify.breakpoint.smAndDown){
       return 1000
     }else if(this.$vuetify.breakpoint.mdAndUp){
       return 700
     } 
    }
  }
}
</script>