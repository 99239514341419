<template>
  <div>
    <!-- <button @click="getPersons">get persons</button> -->
    <!-- <button @click="getSelectedPersons">get persons</button> -->
    <!-- {{ personsJSON }} -->
  </div>
</template>

<script>
import { personsRef } from "./../services/Database";
import { sendEmail } from "./../services/common"
export default {
  data: () => ({
    persons: [],
    personsJSON: "",
  }),
  methods: {
    getSelectedPersons() {
      let array = [];
    //   personsRef.doc('9U5Ag6nuXSFUKahnsHo4').get().then(async snap => {
    //       console.log(snap.data())
    //       let data = snap.data()
    //       try{
    //         await  sendEmail(data)
    //         console.log('Sended')
    //       }
    //       catch(e){
    //           console.log(e)
    //       }
    //     })
      personsRef
        .where("year", "==", 2022)
        .orderBy("timestamp", "desc")
        .get()
        .then(async (snaps) => {
          console.log(snaps.docs.length);
          snaps.forEach((snap) => {
            let data = snap.data();
            array.push(data);
            // console.log(new Date(data.timestamp))
          });
          let filtr = array
            .filter((item) => item.timestamp <= 1641133566014)
          console.log(filtr);
          for(let item of filtr){
              try {     
                  await sendEmail(item)
                  console.log('Email Send -1')
              } catch (error) {
                  console.log(error)
              }
          }
        });

      // console.log(array)
    },
    getPersons() {
      personsRef.get().then((snaps) => {
        snaps.forEach((snap) => {
          this.persons.push(snap.data());
        });
        this.persons = this.persons.filter((item) => !item.year);
        this.personsJSON = JSON.stringify(this.persons);
      });
    },
  },
};
</script>