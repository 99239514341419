<template>
    <div>
        <!-- <p>Ola world .. this is MEXIIIICOO</p> -->
        <Registration 
            :eventId="eventId"
            :eventName="eventName"
            :eventDesc="eventDesc"
        />
    </div>
</template>


<script>
import Registration from '@/components/Registration'
export default {
    components:{
        Registration
    },
    data: () => ({
        eventId: "Some Id",
        eventName: "Ticket to MBA",
        eventDesc: "<p>It is an arranged interactive program between contributing colleges and students where they both can join special counseling program along with exchanging information about the MBA courses.</p><p>This program will provide great help for the MBA aspirants in the north east India region.  Overall, both students and institutes can choose the right things for their objectives.</p>"
    })
}
</script>