<template>
    <div>
        <slot v-bind:selectFile="selectFile"></slot>
        <input ref="fileInput" style="display:none;" type="file" @change="onFileSelected" />               
    </div>
</template>

<script>

import { uploadThroughApi } from "@/services/Upload"

export default {
    props: ["userId"],
    data: () => ({
        myName: "Max"
    }),
    methods: {
        selectFile() {
            this.$refs.fileInput.click()
        },
        onFileSelected(event) {                        
            this.$store.commit("SET_UPLOAD_STATE", true)
            const file = event.target.files[0]
            this.$emit("fileSelected", file)
            const size = Math.ceil((file.size)/1024)
            if ( size > 1024 ) {
                console.log("size dangor")
                this.$emit("error", {
                    type: "MAX_FILE_SIZE_EXCEEDED",
                    msg: "Image size should be less than 1MB",
                    content: file
                }) 
                this.$store.commit("SET_UPLOAD_STATE", false) 
                return             
            }
            uploadThroughApi(file, this.userId)
            .then(res => {
                this.$store.commit("SET_UPLOAD_STATE", false)                
                this.$emit("complete", res)                
            })             
            .catch(err => {
                this.$store.commit("SET_UPLOAD_STATE", false)                
                this.$store.commit("SET_SNACKBAR", { message: "Problem Uploading" })
            })
        }
    }
}
</script>