<template>
  <div class="pb-5 pt-5 mt-5 white">
    <v-container class="mt-5" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex 
          xs12
          md7
          class="text-center py-5" data-aos="fade-right"
     data-aos-duration="1200">
           <h2 class="app-display-1  secondary--text font-weight-light" style="letter-spacing:3px !important;">
            WELCOME TO TICKET TO MBA 4.0
          </h2>
          <div class="grey--text text--darken-1 mt-2" style="letter-spacing:1px; font-family:Noto Serif">
            What is ticket to MBA?
          </div>          
          <div class="body-1 pa-5 mt-5 text-justify" :class="{'mx-5':$vuetify.breakpoint.mdAndUp}" style="font-family:PT Serif ">
            <p>
            Ticket to MBA is an incredible and unique concept, which is an effective way to bring MBA aspiring students and all educational institutes under one roof. 
            Its a leading Education Fair & Convention of Northeast India.TICKET TO MBA gives you the ideal opportunity to learn more about the INDIA'S most recognised business degrees.
            </p>
         
           <div class="text-center">
            <v-btn class="font-weight-bold" text color="#1678B1" style="font-family:'PT Serif';" to="/about-us">
              READ MORE <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
           </div>
          </div>    
        </v-flex>
        <v-flex xs12 md5 v-show="$vuetify.breakpoint.mdAndUp">
          <div class="text-center" data-aos="fade-left"
     data-aos-duration="1200">
            <img src="../assets/new-4.png" alt="" width="100%" height="400px" style="border-radius: 5px">
          </div>
        </v-flex>
      </v-layout>      
    </v-container>    
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
