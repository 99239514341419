<template>
    <div style="margin-bottom:40px;" v-if="!loading">
      <br>
      <v-card flat color="transparent">
        <v-card-title primary-title>
          Registered Applications
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="`Search ${personsCollection.length} participants`"
            single-line
            outlined
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn to="/view-reviews" color="primary" class="white--text mx-3" depressed>VIEW REVIEWS</v-btn>
          <v-btn @click="signOut" color="primary" class="white--text" outlined text depressed>LOG OUT</v-btn>
        </v-card-title>
        <!-- <span v-for="student in presentStudents" :key="student.registrationId">
          {{ student.name }}, 
        </span> -->
        <div>          
          <!-- <v-btn @click="generate()">SHOW</v-btn> -->
          <!-- {{ presentStudents.length }} -->
        </div>
        <v-data-table
          class="mt-4"
          :headers="headers"
          :items="personsCollection"
          :search="search"
          :items-per-page="15"
        >
          <template v-slot:item.present="{ item }"> 
            <!-- <v-chip color="red" dark>{{ item.profileImg }}</v-chip> -->
              <v-switch
                @change="updateAttendance(item.registrationId, $event)"
                :input-value="item.present"
                hint="24.10.20"
              ></v-switch>
            <!-- <v-img src="https://www.pinclipart.com/picdir/middle/181-1814767_person-svg-png-icon-free-download-profile-icon.png" width="90" height="90" class="ma-2" aspect-ratio="1.7" contain></v-img> -->
            </template>
          <template v-slot:item.photoId="{ item }"> 
            <!-- <v-chip color="red" dark>{{ item.profileImg }}</v-chip> -->
            <v-img :src="item.photoId" lazy-src="https://www.pinclipart.com/picdir/middle/181-1814767_person-svg-png-icon-free-download-profile-icon.png" width="90" height="90" class="ma-2" aspect-ratio="1.7" contain></v-img>
            </template>
        </v-data-table>
      </v-card>
    </div>
</template>


<script>
import { triggerAction, triggerActionExt } from "@/services/common.js"
import { updatePerson } from "@/models/Person.js"
import { mapGetters } from "vuex"


  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Registered Name',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          // { text: 'Event', value: 'eventName' },
          { text: 'Registration ID', value: 'registrationId' },
          { text: 'Email', value: 'email' },
          { text: 'Phone', value: 'phone'},
          { text: 'College', value: 'college' },
          { text: 'Course', value: 'course' },
          { text: 'Semester', value: 'semester' },
          // { text: 'food', value: 'food' },
          // { text: 'Address', value: 'address' },
          // { text: 'Id proof', value: 'photoId' },
          { text: 'Reffered By', value: 'refferedBy' },
          { text: 'Present', value: 'present' },
          // { text: 'Comments', value: 'comment' },
        ],
        
      }
    },
    computed: {
      ...mapGetters(["personsCollection", "loading"]),
      presentStudents() {
        return this.personsCollection.filter(item => item.present == true)
      }
    },
    mounted() {
      triggerAction('getPersonsCollection')
          .then(() => { 
            console.log("Done!!")
          })
        .catch(err => { console.log(err) })           
    },  
    methods: {
      generate() {
        // this.presentStudents.forEach
      },
      signOut() {
        triggerActionExt("signOut", {
          hideLoader: true
        })
        .then(() => {
          this.$router.replace("/")
        })
      },
      async updateAttendance(id, data){
        // console.log(id, data)
        await updatePerson(id, data)
        console.log("Done!")
        // updatePerson(data.registrationId, )
      }
    }  
  }
</script>

