<template>
  <div id="register">
    <app-parallax :height="parallaxHeight" :disableScroll="$vuetify.breakpoint.smAndDown" src="eventbg.jpg" >
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex class="text-center pb-5" xs12 data-aos="fade-up" data-aos-duration="1200">
            <h2 class="app-display-1 font-weight-light" style="letter-spacing:3px !important;">
              TICKET TO MBA
            </h2>
            <div class="white--text  mt-2" style="letter-spacing:1px;font-family:Noto Serif">
              Register for the event
            </div>         
          </v-flex>
        </v-layout>
        <v-layout justify-center align-center row wrap class="black--text text-lighten-2 mt-5">                
            <v-flex xs12 md6 class="pa-3 pt-3 px-5 transback" data-aos="fade-right" data-aos-duration="1500">
              <p class="pt-5">It is an arranged interactive program between contributing colleges and students where they both can join special counseling program along with exchanging information about the MBA courses.
            This program will provide great help for the MBA aspirants in the north east India region. Overall, both students and institutes can choose the right things for their objectives.</p>
            <h4>Management Conclave</h4>
            <p class="mt-3 pb-3">Attending Management Conclave, the MBA aspirants will be aware of the various possibilities of pursuing an MBA course also about the different specialisation and an opportunity to interact with the MBA experts from the participating colleges.This in return will help the attendees to gain the right knowledge about MBA.</p>
            <div class="text-center pb-2">
              <v-btn to="/registration/ticket-to-mba" depressed x-large color="primary" large>REGISTER HERE
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
            </v-flex>
            <v-flex xs12 md6 class="px-5" v-if="$vuetify.breakpoint.mdAndUp">
              <v-layout row wrap data-aos="fade-in" data-aos-duration="1500">
                <v-flex xs5 >
                   <v-carousel cycle horizontal :interval="3000" :show-arrows="false" hide-delimiters style="height:207px; border-radius:8px;">
                      <v-carousel-item>
                        <v-img height="207" src="../assets/gallery/1.jpg" />
                      </v-carousel-item>
                      <v-carousel-item>
                          <v-img height="207" src="../assets/gallery/2.jpg" />                                               
                      </v-carousel-item>
                   </v-carousel>            
                </v-flex>
                <v-flex xs7>
                  <v-carousel cycle :interval="5000" reverse :show-arrows="false" hide-delimiters style="height:207px; border-radius:8px; ">
                      <v-carousel-item>
                        <v-img height="207" src="../assets/gallery/3.jpg" />
                      </v-carousel-item>
                      <v-carousel-item>
                          <v-img height="207" src="../assets/gallery/4.jpg" />                                               
                      </v-carousel-item>
                   </v-carousel>         
                </v-flex>
                <v-flex xs7>
                   <v-carousel cycle  :interval="4000" :show-arrows="false" hide-delimiters style="height:207px; border-radius:8px;">
                      <v-carousel-item>
                        <v-img height="207" src="../assets/gallery/5.jpg" />
                      </v-carousel-item>
                      <v-carousel-item>
                          <v-img height="207" src="../assets/gallery/6.jpg" />                                               
                      </v-carousel-item>
                   </v-carousel>            
                </v-flex>
                <v-flex xs5>
                  <v-carousel cycle :interval="3500" reverse vertical :show-arrows="false" hide-delimiters style="height:207px; border-radius:8px;">
                      <v-carousel-item>
                        <v-img height="207" src="../assets/gallery/7.jpg" />
                      </v-carousel-item>
                      <v-carousel-item>
                          <v-img height="207" src="../assets/gallery/8.jpg" />                                               
                      </v-carousel-item>
                   </v-carousel>         
                </v-flex>
               
              </v-layout>
            </v-flex>
        </v-layout>
      </v-container> 
    
    </app-parallax>   
  </div>
</template>

<script>
// import Slider from "@/components/global/Slider"

export default {
  components:{
    // Slider
  },
  data(){
    return{
  
    }
  },
    computed:{
    parallaxHeight(){
     if(this.$vuetify.breakpoint.smAndDown){
       return 800
     }else if(this.$vuetify.breakpoint.mdAndUp){
       return 660
     } 
    }
  }
}
</script>

<style>


.transback{
  background: rgba(255, 255, 255, 0.363);
  border-radius: 10px;
}

</style>