import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { auth } from "./services/Auth"
import FileUpload from "./components/global/FileUpload.vue"
import Preloader from "./components/global/Preloader.vue"
import AppParallax from "./components/global/AppParallax.vue"
import FullScreenDialog from "./components/global/FullScreenDialog.vue"
import Uploader from "./components/global/Uploader.vue"
import Slider from "./components/global/Slider.vue"
// import XImg from "./components/global/XImg"

Vue.config.productionTip = false

Vue.component('Navbar',Navbar);
Vue.component('Footer',Footer);
Vue.component('file-upload', FileUpload)
Vue.component('preloader', Preloader)
Vue.component('fullscreen-dialog', FullScreenDialog)
Vue.component('uploader', Uploader)
Vue.component('app-parallax', AppParallax)
Vue.component('Slider', Slider)
// Vue.component('x-img', XImg)
Vue.prototype.authData = {}

var app;

auth().onAuthStateChanged(() => {
  store.commit("SET_AUTH_DATA", auth().currentUser)
  router.beforeEach((to,from,next)=>{   
    if(to.matched.some(record =>record.meta.requiresAuth) && !store.getters.authData) {
      next("/login")
    }
    else if(to.matched.some(record =>record.meta.requiresGuest) && store.getters.authData) {
      next("/dashboard")
    }    
    next()
  })
  if (!app) { initApp() }
})

function initApp() {
  new Vue({
    created () {
      AOS.init({once:true,disable:'mobile'})
    },
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}