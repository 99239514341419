<template>
    <div>
        <v-container fluid grid-list-md class="pt-0">
            <Herosection header="About Us" subheading="Who we are" background="about1.jpg" />
        </v-container>
        <div class="my-5 pt-5">
            <v-container grid-list-xs>
                <v-row class="mb-5">
                    <v-col cols="12" md=6>
                        <h2 class="app-headline header-title font-weight-light pl-5" style="border-left:5px solid #f37736;"> &nbsp; ABOUT TICKET TO MBA</h2> 
                        <div class="body-1 mt-5 mb-4 pt-5 text-justify grey--text text--darken-2">
                            'Ticket to MBA' is a management fair conducted by 'Premier edu',one of the leading career counselor in North East India over a decade of experiences.
                            Ticket to MBA have vision to provide a platform for the management aspirants to know more about the management courses as well as opportunities in the present scenario.
                            Also we have a aim to help the students to choose the right institution according to the need as well the capability of the students.

                        </div> 
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md=6 fill-height style="position:relative;">
                        <div class="mt-5 pt-5">
                            <div class="elevation-5" style="height:250px; width:300px;position: absolute; top: 70px; left: 100px;z-index:2">
                                <v-img src="../assets/gallery/2.jpg" height="100%" ></v-img>
                            </div>
                            <div class="elevation-5" style="height:250px; width:300px;position: absolute;top: 0; left: 300px;" >
                                <v-img src="../assets/gallery/4.jpg" height="100%" ></v-img>
                            </div>
                        </div>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="grey--text text--darken-2"></v-col>
                    
                </v-row>
            </v-container>
        </div>
        <Footer />
    </div>
</template>


<script>
import Herosection from '@/components/Herosection'
export default {
    components:{
        Herosection
    }
}
</script>