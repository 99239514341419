<template>
<!-- To add slides, name the slot as slide-1, slide-2, etc and pass the num of slides as a prop. -->
  <div>
    <flickity ref="flickity" :options="options">
      <slot>
      <!-- <slot 
        v-for="i in numSlides" 
        :name="`slide-${i}`" /> -->
      </slot>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity"

export default {
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({        
        contain: true,  
        freeScroll : true,
        autoPlay: true,     
      })
    },
    numSlides: {
      default: 0
    }    
  },
  components: {
    Flickity
  }  
}
</script>

<style scoped>


</style>