import { images } from "./Storage";
// import store from "./../store";
import randomstring from "random-string"

export function uploadThroughApi(file) {
    return new Promise((resolve, reject) => {        
        const fileName = randomstring({ length: 32 })
        const localFileName = file.name
        const fileExtension = localFileName.split('.').pop()
        const path = images.child(`${fileName}.${fileExtension}`)   
        const uploadTask = path.put(file)        
        uploadTask.on('state_changed', 
            function(snapshot){
                // eslint-disable-next-line
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;  // eslint-disable-line no-use-before-define
                           
            }, 
            function(error) {
                reject(error)
            }, 
            function() {
                return uploadTask.snapshot.ref.getDownloadURL()
                    .then(downloadURL =>  {
                        // store.commit("SET_UPLOAD_INFO", {
                        //     url: downloadURL
                        // })                        
                        resolve({
                            name: localFileName,
                            url: downloadURL
                        }) 
                    })
                    // .then(() => resolve())
                    .catch(err => reject(err))                
            });
    })
}