import { personsRef, personsBkpRef } from "../services/Database";

export function backup() {
  return new Promise((resolve, reject) => {
    personsRef.get()
    .then(snaps => {
      snaps.forEach(async snap => {
        // await personsBkpRef.add(snap.data())
        await personsRef.doc(snap.id).update({
          present: false
        })
        console.log("Updated")
      })
      resolve()
    })
    .catch(e => {
      reject(e)
    }) 
  })
}

// export function updatePerson() {
//   return personsBkpRef    
//     .update({
//       present: false
//     })
//     .then(doc => console.log("Updated"))
//     .catch(err => err)
// }
