<template>
  <div>
    <!-- <input ref="fileInput" style="display:none;" type="file" @change="onFileSelected"/>        -->
    <div class="text-xs-center">
      <v-dialog
        :value="uploading"
        persistent
        width="250"
      >
        <v-card>
          <v-card-text class="pb-0">
              <v-layout class="pa-5" row justify-center align-center>
                <v-flex xs3 >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>  
                </v-flex>
                <v-flex class="pl-3" xs9>
                  Uploading...
                </v-flex>
              </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>        
  </div>
</template>

<script>
// import { uploadThroughApi } from "@/services/Upload"
import { mapGetters } from "vuex"

export default {
  data: () =>({
    // files: null
  }),
  computed: {
    ...mapGetters(['uploading']),
    // filesCount() {
    //   return this.files? this.files.length : 0
    // },
  },
  // methods: {
  //   selectFile() {
  //       this.$refs.fileInput.click()
  //   },
  //   onFileSelected(event) {
  //     this.files = event.target.files
  //     this.$store.commit('SET_UPLOAD_STATE', false)
  //     uploadThroughApi(this.files[0])
  //       .then(() => {
  //         this.$store.commit("SET_SNACKBAR", { message: "Uploaded Successfully" })
  //         this.files = null
  //       })
  //   },
  // },  
  // watch: {
  //   uploading(current) {
  //     if(current) {
  //       this.selectFile()
  //       this.$store.commit('SET_UPLOAD_STATE', false)
  //     }
  //   }
  // }
}
</script>