<template>
  <div>
    <v-container fluid grid-list-md class="pt-0" >
      <Herosection background="blogs.jpg" class="mb-5" header="Blogs" subheading="Explore stories about us" />          
      <v-layout row wrap>
        <v-flex class="my-5" v-for="i in 3"
          :key="`blog-${i}`"
           md10
           offset-md1>
          <v-layout row wrap>
            <v-flex md5>
              <v-card
                flat
              >                
                <v-img
                  aspect-ratio="1.9"
                  src="https://images.unsplash.com/photo-1560523159-fbe43c0b465f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                />
              </v-card>
            </v-flex>
            <v-flex md7>
              <v-layout :class="{'mt-3': $vuetify.breakpoint.smAndDown, 'ml-3 mt-1': $vuetify.breakpoint.mdAndUp}" class="pl-5 mx-0" style="border-left:5px solid rgb(243, 119, 54);" column wrap >
                <v-flex >
                  <h2 class="app-headline font-weight-bold header-title">
                    WHAT HAPPENS WHEN YOU ATTEND YOUR FIRST COUNSELLING SESSION  
                  </h2>                  
                </v-flex>
                <v-flex>
                  <div class="grey--text text--darken-2 body-1">
                    <i>John Doe. May 29, 2018</i>
                  </div>
                </v-flex>
              </v-layout>
              <div :class="{'': $vuetify.breakpoint.smAndDown, 'ml-3': $vuetify.breakpoint.mdAndUp}" class="body-1 mt-4 text-justify">
                {{ shortText }}                
                &nbsp; <router-link to="/">Read More...</router-link>
              </div>              
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import Herosection from '@/components/Herosection'

export default {
  components:{
        Herosection
    },
    data:()=>({
      text: "The myths of the quest started when Perseus the son of Danae and Zeus. It was foretold to Danae’s father, Acrisius(the king of Argos) that Danae’s son would kill him. So Acrisius locked his daughter Danae away in a bronze chamber but Zeus impregnated her anyway by transforming himself in a form of golden spring. Out of fear Acrisius hurled his daughter and his grandson in a wooden chest into the sea. But they were rescued by Dictys and lived in the island of Seriphos.",
      maxLenght: 320
    }),
    computed:{
      shortText(){
        return this.text.slice(0,this.maxLenght)
      }
    }
}
</script>

<style>
.v-expansion-panel {
  box-shadow: none;
}
</style>